.ratingContainer {
  display: flex;
  font-weight: var(--palette-font-weight-bold);
  align-items: center;

  :global .MuiRating-root {
    margin-top: -2px;
  }

  :global .MuiRating-icon svg {
    flex-shrink: 0;
  }

  :global .MuiRating-iconFilled {
    color: var(--color-rating-filled);
  }
  :global .MuiRating-iconEmpty {
    color: var(--color-rating-empty);
  }
}

.ratingGold {
  --color-rating-filled: #ff9d28;
  --color-rating-empty: #b5b5b5;
}

.ratingAmount.ratingAmount {
  margin-inline-end: 6px;
}

.ratingColorGray {
  color: var(--palette-gray-400);
}

.ratingColorWhite {
  color: var(--palette-white);
}

.ratingColorDark {
  color: var(--palette-dark);
}
