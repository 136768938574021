@import '@/styles/breakpoints.module';

.cardHeader {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--palette-gray-600);
  color: var(--color-text-primary);
  margin-bottom: 16px;

  @include tablet {
    margin-bottom: 24px;
    padding-bottom: 24px;
  }

  svg {
    height: 24px;
    width: 24px;
    @include tablet {
      height: 32px;
      width: 32px;
    }
    color: var(--palette-primary-700);
    margin-inline-end: 8px;
  }
}
