@import '@/styles/breakpoints.module';

.intercomCustomLauncherContainer {
  pointer-events: auto;
  align-self: end;
  display: inline-block;
  padding-inline-end: 20px;
  padding-bottom: 20px;
  @include desktop {
    padding-inline-end: 46px;
  }
}

.customLauncher {
  cursor: pointer;
  width: 48px;
  height: 48px;
  transition:
    transform 100ms linear,
    opacity 80ms linear;

  .intercomCustomLauncherButtonLoader {
    display: flex;
    svg {
      circle {
        stroke-linecap: round;
        stroke: white;
      }
    }
  }

  & > svg:not(.intercomCustomLauncherButtonLoader) {
    width: 24px;
    height: 24px;
    color: var(--palette-white);
  }

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--palette-primary-700);
  border-radius: 100px;

  &:hover {
    transition: transform 250ms cubic-bezier(0.33, 0, 0, 1);
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.85);
    transition: transform 134ms cubic-bezier(0.45, 0, 0.2, 1);
  }
}

.loading {
  animation-direction: alternate;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-name: blink;
  animation-timing-function: ease-in-out;
}

@keyframes blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}
